var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ALIGN, COLOR, FLEX_DIRECTION, SIZE, SPACE, styles, useId } from '../../../hooks';
import { SWITCH_VARIANT, TEXT_ROLES, Text, Touchable, View } from '../../primitives';
import { style } from './OptionItem.style';
import { InputOption } from '../InputOption';
const OptionItem = (_a) => {
    var { checked, detail, detailStyle, disabled, name, outlined = true, text, title, type = SWITCH_VARIANT.RADIO, unselected, value, onChange } = _a, others = __rest(_a, ["checked", "detail", "detailStyle", "disabled", "name", "outlined", "text", "title", "type", "unselected", "value", "onChange"]);
    const uid = useId();
    const handleChange = () => {
        if (onChange)
            onChange(value);
    };
    const colorText = disabled ? COLOR.TEXT_DISABLED : COLOR.TEXT;
    const hasBorder = outlined && !unselected && checked;
    return (React.createElement(Touchable, Object.assign({}, others, { borderColor: hasBorder ? (disabled ? COLOR.BORDER_INTERACTIVE : COLOR.BORDER_INPUT_FOCUS) : undefined, backgroundColor: COLOR.BG_BASE, onPress: !disabled ? handleChange : undefined, style: styles(style.item, !hasBorder && style.itemWithoutBorder, others.style), wide: true }),
        React.createElement(InputOption, { "aria-describedby": `${uid}_text ${uid}_detail`, checked: !unselected ? checked : false, disabled: disabled, id: uid, marginRight: SPACE.SPACE_4, name: name, type: type, value: value }),
        React.createElement(View, { flexDirection: FLEX_DIRECTION.COLUMN, flex: SIZE.XS, style: style.content },
            React.createElement(Text, { action: true, role: TEXT_ROLES.label, color: colorText, ellipsizeMode: true, accessibilityLabelledBy: uid }, title),
            !!text && (React.createElement(Text, { detail: true, level: 1, color: COLOR.TEXT_MEDIUM, ellipsizeMode: true, nativeID: `${uid}_text` }, text))),
        !!detail && (React.createElement(Text, { align: ALIGN.CENTER, color: colorText, detail: true, nativeID: `${uid}_detail`, style: [style.detail, detailStyle] }, detail))));
};
OptionItem.displayName = 'OptionItem';
export { OptionItem };
