var Routes;
(function (Routes) {
    Routes["DEFAULT"] = "*";
    Routes["HOME"] = "/";
    Routes["COMPLETE_YOUR_LOOK"] = "complete-your-look";
    Routes["LOOKS_BY_ANCHOR_GARMENT"] = "complete-your-look/:anchorProductVariantId";
    Routes["CATEGORIES"] = "categories";
    Routes["CATEGORY"] = "categories/:categoryId";
    Routes["CHECKOUT_CONFIRMATION"] = "checkout-confirmation/:orderId";
    Routes["CHECKOUT"] = "checkout";
    Routes["ORDERS"] = "orders";
    Routes["SALES"] = "sales";
    Routes["SALE"] = "sales/:categoryId";
    Routes["WISHLIST"] = "wishlist";
    Routes["CART"] = "cart";
    Routes["PRODUCT_DETAIL"] = "detail/:variantId";
    Routes["PRODUCT_LOOK_DETAIL"] = "look/:lookId/detail/:variantId";
    Routes["PRODUCT_CATEGORY_DETAIL"] = "category/:categoryId/detail/:variantId";
    Routes["RETURN_CONFIRMATION"] = "orders/return-confirmation/:orderId";
    Routes["ORDER"] = "orders/:orderId";
    Routes["TRENDING_FOR_YOU"] = "trending-for-you";
})(Routes || (Routes = {}));
const PRIMARY_ROUTES = [Routes.COMPLETE_YOUR_LOOK, Routes.CATEGORIES, Routes.SALES];
export { Routes, PRIMARY_ROUTES };
