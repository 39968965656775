var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { LinearGradient } from 'expo-linear-gradient';
import React, { useLayoutEffect, useState } from 'react';
import { IS_JEST, capitalize } from '../../../helpers';
import { COLOR, styles, useDevice } from '../../../hooks';
import { Motion, Text, View } from '../../primitives';
import { Theme } from '../../../theming';
import { TEXT_GAP } from './Skeleton.definition';
import { style } from './Skeleton.style';
const Skeleton = (_a) => {
    var { backgroundColor = COLOR.BG_PRIMARY_LIGHT, children, color = COLOR.TEXT_DISABLED, height, width } = _a, others = __rest(_a, ["backgroundColor", "children", "color", "height", "width"]);
    const { screen } = useDevice();
    const [layout, setLayout] = useState();
    useLayoutEffect(() => {
        if (height && width)
            setLayout({ height, width });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleLayout = ({ nativeEvent }) => {
        if (height && width) {
            setLayout({ height, width });
        }
        else if (nativeEvent && nativeEvent.layout) {
            setLayout(nativeEvent.layout);
        }
    };
    const { colorBgBaseTransparent, motionSkeleton = 1200, [`color${capitalize(color)}`]: hexColor, } = Theme.get();
    return (React.createElement(View, Object.assign({}, others),
        React.createElement(View, { backgroundColor: backgroundColor, style: styles(style.container, layout ? { height: layout.height, width: layout.width + (children ? TEXT_GAP : 0) } : { height, width }, others.style), onLayout: !children && !layout ? handleLayout : undefined },
            React.createElement(Motion, { disabled: !layout || IS_JEST, duration: motionSkeleton, loops: layout ? -1 : undefined, style: style.content, value: {
                    left: 0,
                    translateX: screen.width * (layout ? 1 : -1),
                } },
                React.createElement(LinearGradient, { colors: [colorBgBaseTransparent, hexColor, hexColor, colorBgBaseTransparent], end: { x: 1, y: 0 }, locations: [0, 0.25, 0.75, 1], start: { x: 0, y: 0 }, style: style.content })),
            children && (React.createElement(Text, Object.assign({}, others, { accessibilityHidden: true, style: style.text, onLayout: handleLayout }), children)))));
};
Skeleton.displayName = 'Skeleton';
export { Skeleton };
