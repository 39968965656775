import { UTC, getToday, toLocale } from '../../../../helpers';
const normalize = (weekday) => weekday.substring(0, 1).replace(/(\b\w)/gi, (char) => char.toUpperCase());
export const getWeekDays = (locale) => {
    const today = getToday();
    const weekdays = [];
    const date = UTC(new Date(today.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))));
    Array.from(Array(7).keys()).forEach(() => {
        weekdays.push(normalize(toLocale(date, { locale: locale, weekday: 'short' })));
        date.setDate(date.getDate() + 1);
    });
    return weekdays;
};
