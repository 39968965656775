import React, { Fragment } from 'react';

export const translate = ({ dictionary = {}, key: { id = '', defaultMessage } = {}, bindings = {} } = {}) => {
  let value = dictionary[id] || defaultMessage;
  let children;

  if (value && Object.keys(bindings).length > 0) {
    children = [];
    Object.keys(bindings).forEach((key, index) => {
      const [start, end = ''] = value.split(`{${key}}`);

      children.push(<Fragment key={index}>{start}</Fragment>);
      children.push(<Fragment key={key}>{bindings[key]}</Fragment>);

      value = end;
    });
    if (value) children.push(<Fragment key={'end'}>{value}</Fragment>);
  }

  return children || value || id;
};
