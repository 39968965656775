import { proxy, getToken } from '@package/services/ioc';

interface BodyInit {
  method: string;
  body?: any;
}

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const getHeaders = async (): Promise<HeadersInit> => {
  return {
    authorization: `Bearer ${await getToken()}`,
    ...DEFAULT_HEADERS,
  };
};

export const commonFetch = async (url: string, config?: BodyInit) => {
  return fetch(proxy(url), {
    ...config,
    body: JSON.stringify(config?.body),
    headers: await getHeaders(),
  })
    .then(async (res: Response) => {
      if (!res.ok) {
        const error = await res.json();
        return Promise.reject(error);
      }

      return res.json();
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const get = (url: string) => {
  return commonFetch(url, { method: 'GET' });
};

const post = (url: string, payload: Record<string, any>) => {
  return commonFetch(url, { method: 'POST', body: payload });
};

const put = (url: string, payload: Record<string, any>) => {
  return commonFetch(url, { method: 'PUT', body: payload });
};

const HttpClient = {
  get,
  post,
  put,
  create: (baseUrl: string) => ({
    get: (url: string) => get(`${baseUrl}${url}`),
    post: (url: string, config: any) => post(`${baseUrl}${url}`, config),
    put: (url: string, config: any) => put(`${baseUrl}${url}`, config),
  }),
};

export { HttpClient };
