var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { testID as getTestId } from '../../../helpers';
import { ALIGN, COLOR, FLEX_DIRECTION } from '../../../hooks';
import { Text, Touchable, View } from '../../primitives';
import { getCaption } from './helpers';
import { style } from './Rating.style';
const Rating = (_a) => {
    var { max = 5, maxCaption, min = 1, minCaption, title, value, onChange, testID } = _a, others = __rest(_a, ["max", "maxCaption", "min", "minCaption", "title", "value", "onChange", "testID"]);
    const ratingValues = Array.from(Array(max), (item, index) => index + min);
    const small = max > 5;
    return (React.createElement(View, Object.assign({}, others, { testID: testID }),
        title && React.createElement(Text, { style: style.title }, title),
        React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW, justifyContent: ALIGN.BETWEEN }, ratingValues.map((ratingValue) => {
            const active = value === ratingValue;
            return (React.createElement(View, { flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.CENTER, alignItems: ALIGN.CENTER, key: ratingValue },
                small && (React.createElement(Text, { align: ALIGN.CENTER, color: COLOR.CONTENT, detail: true, style: [style.value, style.valueSmall], wide: true }, ratingValue)),
                React.createElement(Touchable, Object.assign({ alignItems: ALIGN.CENTER, backgroundColor: active ? COLOR.BG_INTERACTIVE_SELECTED : COLOR.BG_INTERACTIVE_ACTIVE, justifyContent: ALIGN.CENTER, key: ratingValue, style: [small ? style.itemSmall : style.item], onPress: onChange ? () => onChange(ratingValue) : undefined }, getTestId(testID, ratingValue.toString())), !small && (React.createElement(Text, { align: ALIGN.CENTER, color: COLOR.TEXT, detail: true, level: 1, style: style.value, wide: true }, ratingValue)))));
        })),
        minCaption && maxCaption && (React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW, justifyContent: ALIGN.BETWEEN, style: style.captionContainer },
            React.createElement(Text, { detail: true, color: COLOR.TEXT_MEDIUM }, getCaption({ num: min, text: minCaption })),
            React.createElement(Text, { detail: true, color: COLOR.TEXT_MEDIUM }, getCaption({ num: max, text: maxCaption }))))));
};
Rating.displayName = 'Rating';
export { Rating };
