import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    button: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonDesktop: {
        flexDirection: 'row',
        marginLeft: '$space6',
        flex: 'auto',
        justifyContent: 'space-between',
    },
    iconContainer: {
        alignSelf: 'center',
        marginBottom: '$space1',
    },
    iconDesktop: {
        marginRight: '$space2',
        marginBottom: 0,
    },
    text: {
        overflow: 'hidden',
    },
});
