var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback } from 'react';
import { BUTTON_VARIANT, Button, COLOR, Image, Link, SIZE, SPACE, TEXT_ROLES, Text, Touchable, View, styles, useDevice, } from '../../..';
import { Theme } from '../../../theming';
import { BANNER_TYPE } from './Banner.definition';
import { borderRadiusVariantStyle, style } from './Banner.style';
export const Banner = (_a) => {
    var { title, pretitle, description, image, primaryButton, secondaryButton, link, opacity = 0.5, type = BANNER_TYPE.ROUNDED, scaled = true, nativeID } = _a, others = __rest(_a, ["title", "pretitle", "description", "image", "primaryButton", "secondaryButton", "link", "opacity", "type", "scaled", "nativeID"]);
    const { screen } = useDevice();
    const { colorBgPrimary, colorBgBaseTransparent } = Theme.get();
    const Container = secondaryButton ? View : Touchable;
    const handleOnPressContainer = useCallback((event) => {
        if (primaryButton) {
            primaryButton.onPress(event);
        }
        else if (link) {
            link.onPress({ href: link.href });
        }
    }, [link, primaryButton]);
    return (React.createElement(View, Object.assign({ nativeID: nativeID }, others),
        React.createElement(Container, { onPress: handleOnPressContainer, style: styles(style.container, scaled ? style.scaled : style.notScaled, borderRadiusVariantStyle[type]) },
            React.createElement(Image, { resizeMode: "cover", src: image.src, srcSet: image.srcSet, style: style.backgroundImage },
                React.createElement(LinearGradient, { style: [style.gradient, { opacity }], colors: [colorBgBaseTransparent, colorBgPrimary] })),
            React.createElement(View, { layer: SIZE.XS, style: !scaled ? style.contentMaxWidth : undefined },
                React.createElement(Text, { hidden: !pretitle, action: true, level: 3, color: COLOR.TEXT_LIGHT, role: TEXT_ROLES.h1 }, pretitle),
                React.createElement(Text, { heading: true, level: screen.S ? 3 : 2, color: COLOR.TEXT_LIGHT, role: TEXT_ROLES.p }, title),
                React.createElement(Text, { hidden: !description, detail: screen.S, level: screen.S ? 1 : 3, color: COLOR.TEXT_LIGHT, role: TEXT_ROLES.p }, description),
                React.createElement(View, { marginTop: SPACE.SPACE_6 },
                    primaryButton && (React.createElement(Button, { icon: primaryButton.icon, onPress: primaryButton.onPress, small: true, style: style.alignStart, variant: BUTTON_VARIANT.PRIMARY_LIGHT, wide: Boolean(secondaryButton), testID: nativeID ? `${nativeID}-primary` : undefined }, primaryButton.text)),
                    secondaryButton && (React.createElement(Button, { icon: secondaryButton.icon, marginTop: SPACE.SPACE_4, onPress: secondaryButton.onPress, small: true, variant: BUTTON_VARIANT.PRIMARY_LIGHT, wide: true, testID: nativeID ? `${nativeID}-secondary` : undefined }, secondaryButton.text)),
                    link && (React.createElement(Link, { href: link.href, onPress: link.onPress, color: COLOR.TEXT_LIGHT, underlined: true, action: true, level: 3, testID: nativeID ? `${nativeID}-link` : undefined }, link.text)))))));
};
Banner.displayName = 'Banner';
