var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ButtonIcon } from '../../atoms';
import { ALIGN, COLOR, DISPLAY, useDevice } from '../../../hooks';
import { Text, Touchable } from '../../primitives';
import { style } from './FooterItem.style';
export const FooterItem = (_a) => {
    var { active, color = COLOR.TEXT_MEDIUM, colorActiveIcon = COLOR.ICON, colorActiveText = COLOR.TEXT, icon, onPress, text, notification } = _a, others = __rest(_a, ["active", "color", "colorActiveIcon", "colorActiveText", "icon", "onPress", "text", "notification"]);
    const { screen } = useDevice();
    return (React.createElement(Touchable, Object.assign({}, others, { style: [style.button, screen.L && style.buttonDesktop], onPress: onPress }),
        icon && (React.createElement(ButtonIcon, { color: active ? colorActiveIcon : color, name: icon, notification: notification, style: [style.iconContainer, screen.L && style.iconDesktop], small: true })),
        text && (React.createElement(Text, { align: ALIGN.CENTER, color: active ? colorActiveText : color, detail: !screen.L, display: DISPLAY.BLOCK, ellipsizeMode: true, action: screen.L, level: 3, style: [style.text] }, text))));
};
FooterItem.displayName = 'FooterItem';
