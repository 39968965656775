import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React from 'react';

const DEFAULT_OPTIONS = {
  queries: {
    enabled: true,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    retry: false,
  },
} as const;

const queryClientContext = React.createContext<QueryClient | undefined>(undefined);
const defaultQueryClient = new QueryClient({ defaultOptions: DEFAULT_OPTIONS });

type ReactQueryProviderProps = { children: React.ReactNode; queryClient?: QueryClient };

const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({ children, queryClient }) => {
  return (
    <QueryClientProvider client={queryClient || defaultQueryClient} context={queryClientContext}>
      {children}
    </QueryClientProvider>
  );
};

export { DEFAULT_OPTIONS, queryClientContext, ReactQueryProvider };
