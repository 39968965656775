import { ALIGN, Button, BUTTON_VARIANT, INPUT_TYPES, InputField, Link, View, ViewProperties } from '@lookiero/aurora';
import { useLocale } from '@package/core/infrastructure/localization/useLocale';
import React, { useEffect, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

import { IDS, KEYBOARD, TEXT } from './PromoInput.definition';
import { style } from './PromoInput.styles';
import { TickIcon } from './TickIcon';

export interface PromoInputProps extends ViewProperties {
  showLink?: boolean;
  disabled?: boolean;
  loading?: boolean;
  valid?: boolean;
  hideButtonOnEmpty?: boolean;
  value?: string;
  changeCodeLabel?: boolean;
  onChange?: (value: string) => void;
  onApply: (value: string) => void;
  buttonVariant?: BUTTON_VARIANT;
  buttonSmall?: boolean;
}

const PromoLink: React.FC<{ onPress: () => void; children: React.ReactNode }> = ({ onPress, children }) => {
  return (
    <Link onPress={onPress} detail level={2} underlined align={ALIGN.CENTER} nativeID={IDS.ADD_PROMO_LINK}>
      {children}
    </Link>
  );
};

const enterKeyHasBeenPressed = (event: NativeSyntheticEvent<TextInputKeyPressEventData>): boolean => {
  const pressedEnter = event.nativeEvent.key === KEYBOARD.ENTER;
  if (pressedEnter) event.preventDefault();
  return pressedEnter;
};

const PromoInput: React.FC<PromoInputProps> = ({
  showLink = false,
  disabled = false,
  loading = false,
  valid = false,
  hideButtonOnEmpty = false,
  value = '',
  changeCodeLabel = false,
  onChange = () => undefined,
  onApply,
  buttonVariant = BUTTON_VARIANT.PRIMARY,
  buttonSmall = true,
  ...rest
}) => {
  const [displayLink, setDisplayLink] = useState(showLink);
  const [draftValue, setDraftValue] = useState('');

  const { translate } = useLocale();

  const isInputDisabled = disabled || loading;
  const isButtonDisplayed = !valid && (draftValue || (!hideButtonOnEmpty && !draftValue));
  const isButtonDisabled = disabled || loading || !draftValue;

  const hideLink = (): void => {
    setDisplayLink(false);
  };

  const handleOnPress = (): void => {
    if (isButtonDisplayed && !isButtonDisabled) {
      onApply(draftValue);
    }
  };

  const handleOnKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>): void => {
    if (enterKeyHasBeenPressed(event)) handleOnPress();
  };

  const handleOnChange = (value: string): void => {
    setDraftValue(value);
    onChange(value);
  };

  useEffect(() => {
    setDraftValue(value);
  }, [value]);

  const renderPromoLink = showLink && displayLink && !loading && !value;

  return (
    <View {...rest}>
      {renderPromoLink ? (
        <PromoLink onPress={hideLink}>{translate(TEXT.PROMO_LINK)}</PromoLink>
      ) : (
        <>
          <View>
            <InputField
              testID="promocode-input"
              name="promocode-input"
              type={INPUT_TYPES.text}
              label={translate(TEXT.PROMO_PLACEHOLDER)}
              value={draftValue}
              onChange={handleOnChange}
              disabled={isInputDisabled}
              onKeyPress={handleOnKeyPress}
              trimSpaces
              id={IDS.ADD_PROMO_INPUT}
            />
            {valid && <TickIcon />}
          </View>
          {isButtonDisplayed && (
            <Button
              testID="validate-promocode-button"
              style={style.button}
              busy={loading}
              disabled={isButtonDisabled}
              onPress={handleOnPress}
              variant={buttonVariant}
              small={buttonSmall}
              nativeID={IDS.ADD_PROMO_BUTTON}
            >
              {translate(changeCodeLabel ? TEXT.PROMO_CHANGE_CODE : TEXT.PROMO_APPLY_CODE)}
            </Button>
          )}
        </>
      )}
    </View>
  );
};

export { PromoInput };
