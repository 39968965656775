var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, cloneElement } from 'react';
import { FLEX_DIRECTION } from '../../../hooks';
import { ScrollView } from '../../primitives';
const Slider = (_a) => {
    var { children, selected = 0, snapInterval, onChange } = _a, others = __rest(_a, ["children", "selected", "snapInterval", "onChange"]);
    const handleScroll = ({ X }) => {
        const next = typeof X !== 'undefined' && X < snapInterval ? 0 : Math.round(X / snapInterval);
        if (onChange && next !== selected)
            onChange(next);
    };
    return (React.createElement(ScrollView, Object.assign({}, others, { animated: true, flexDirection: FLEX_DIRECTION.ROW, horizontal: true, scrollTo: selected * snapInterval, snapInterval: snapInterval, onScroll: handleScroll }), Children.map(children, (child, index) => {
        return cloneElement(child, { key: index });
    })));
};
Slider.displayName = 'Slider';
export { Slider };
