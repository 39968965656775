var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { COLOR, DISPLAY, SIZE, styles, useId } from '../../../hooks';
import { Icon, TEXT_ROLES, Text, Touchable } from '../../primitives';
import { getVariant } from './helpers';
import { OPTION_VARIANT } from './Option.definition';
import { style } from './Option.style';
import { InputOption } from '../InputOption';
const Option = (_a) => {
    var { checked, disabled, icon, name, small, title, type, unselected, value, variant = OPTION_VARIANT.CARD, onChange } = _a, others = __rest(_a, ["checked", "disabled", "icon", "name", "small", "title", "type", "unselected", "value", "variant", "onChange"]);
    const uid = useId();
    const handleChange = () => {
        if (onChange) {
            onChange(value);
        }
    };
    const { backgroundColor, borderColor, label = {}, style: variantStyle = [], styleInput: variantStyleInput = [], visibleSwitch, } = getVariant(variant, {
        checked,
        disabled,
        small,
        unselected,
    });
    return (React.createElement(Touchable, Object.assign({}, others, { backgroundColor: backgroundColor, borderColor: borderColor, style: styles(style.container, ...variantStyle, !visibleSwitch && style.noVisibleSwitch, others.style), onPress: !disabled && onChange ? handleChange : undefined, wide: !small }),
        React.createElement(InputOption, { checked: !unselected ? checked : undefined, disabled: disabled, display: visibleSwitch ? undefined : DISPLAY.NONE, id: uid, name: name, type: type, value: value, style: [style.input, variantStyleInput] }),
        !!icon && React.createElement(Icon, { color: label.color || COLOR.ICON_LIGHT, name: icon, marginRight: SIZE.M }),
        React.createElement(Text, Object.assign({}, label, { ellipsizeMode: small, flex: others.flex, role: TEXT_ROLES.label }), title)));
};
// ! Will be deprecated in v4 ------------------------------------------------
const OptionButton = (others) => React.createElement(Option, Object.assign({}, others, { variant: OPTION_VARIANT.BUTTON }));
const OptionCard = (others) => React.createElement(Option, Object.assign({}, others, { variant: OPTION_VARIANT.CARD }));
const OptionPill = (others) => React.createElement(Option, Object.assign({}, others, { variant: OPTION_VARIANT.PILL }));
// !--------------------------------------------------------------------------
Option.displayName = 'Option';
OptionButton.displayName = 'OptionButton';
OptionCard.displayName = 'OptionCard';
OptionPill.displayName = 'OptionPill';
export { Option, OptionButton, OptionCard, OptionPill };
