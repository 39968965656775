import { Application } from '@package/core/application';
import { StrategyProvider } from '@package/ui/providers/StrategyProvider';
import React, { useMemo } from 'react';

import { PromoComponent, PromoComponentProps } from './PromoComponent';

export const PromoComponentContainer: React.FC<PromoComponentProps> = (props) => {
  const { service } = props;
  const application = useMemo(() => Application(service), [service]);
  return (
    <StrategyProvider application={application}>
      <PromoComponent {...props} />
    </StrategyProvider>
  );
};
