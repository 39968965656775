import StyleSheet from 'react-native-extended-stylesheet';
const borderRadiusVariantStyleRecord = {
    rounded: {
        borderRadius: '$borderRadius5',
    },
    squared: {
        borderRadius: 0,
    },
};
const borderRadiusVariantStyle = StyleSheet.create(borderRadiusVariantStyleRecord);
const style = StyleSheet.create({
    container: {
        padding: '$space6',
        overflow: 'hidden',
        justifyContent: 'flex-end',
        width: '100%',
    },
    scaled: {
        aspectRatio: 4 / 5,
    },
    notScaled: {
        height: '100%',
    },
    contentMaxWidth: {
        maxWidth: 465,
    },
    backgroundImage: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
    },
    gradient: {
        display: 'flex',
        flex: 1,
    },
    alignStart: {
        alignSelf: 'flex-start',
    },
});
export { style, borderRadiusVariantStyle };
