var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Children, cloneElement, useEffect, useMemo, useState } from 'react';
import { INPUT_TYPES, VIEW_ROLES, View } from '../../primitives';
import { BOOLEAN_TYPES } from './Form.definition';
import { getChildrenErrors, getChildrenValues, getField, groupState } from './helpers';
const Form = (_a) => {
    var { children, debounce = 0, schema = {}, validateOnMount = false, onBlur, onChange, onError, onFocus, onSubmit } = _a, others = __rest(_a, ["children", "debounce", "schema", "validateOnMount", "onBlur", "onChange", "onError", "onFocus", "onSubmit"]);
    const [error, setError] = useState({});
    const [initialValue, setInitialValue] = useState({});
    const [touched, setTouched] = useState({});
    const [values, setValues] = useState({});
    useEffect(() => {
        const nextValues = getChildrenValues(children);
        const nextChildrenKeys = Object.keys(nextValues).sort();
        if (JSON.stringify(nextChildrenKeys) !== JSON.stringify(Object.keys(initialValue).sort())) {
            setInitialValue(nextValues);
            setValues(nextValues);
            if (validateOnMount) {
                const nextError = getChildrenErrors({ children, schema, values: nextValues, touched, forceCheck: true });
                setError(nextError);
                onError && onError(nextError);
            }
            else {
                setError({});
            }
            setTouched({});
        }
        // ! TODO: Probably handleChange() sets the value correctly, this condition is not necessary.
        // else {
        //   const collision = nextChildrenKeys.some((key) => JSON.stringify(values[key]) !== JSON.stringify(nextValues[key]));
        //   if (collision) setValues(nextValues);
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);
    useEffect(() => {
        if (!onChange || values === initialValue)
            return;
        const timer = setTimeout(() => onChange(values, groupState({ initialValue, value: values, touched })), debounce);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    const handleChange = (field, fieldValue) => {
        setValues(() => {
            const nextValues = Object.assign(Object.assign({}, values), { [field]: fieldValue });
            handleError(nextValues, false);
            return nextValues;
        });
    };
    const handleError = (currentValues, forceCheck = false) => {
        const nextError = getChildrenErrors({ children, schema, values: currentValues, touched, forceCheck });
        const hasError = Object.keys(nextError).length > 0;
        const changed = JSON.stringify(error) !== JSON.stringify(nextError);
        if (changed) {
            setError(nextError);
            onError && onError(nextError, hasError);
        }
        return { changed, errors: nextError, hasError };
    };
    const handleFocus = (field, event) => {
        setTouched(Object.assign(Object.assign({}, touched), { [field]: true }));
        if (onFocus)
            onFocus(field, event);
    };
    const handleSubmit = (event) => {
        const { errors, hasError } = handleError(values, true);
        if (hasError && onError)
            onError(errors, hasError);
        else if (onSubmit)
            onSubmit(values, groupState({ initialValue, value: values, touched }), event);
    };
    return useMemo(() => (React.createElement(View, Object.assign({}, others, { role: VIEW_ROLES.form }), Children.map(children, (child, index) => {
        if (!child)
            return;
        const { props = {} } = child || {};
        const { type } = props;
        const field = getField(props);
        return cloneElement(child, Object.assign({ key: index }, (field
            ? Object.assign(Object.assign(Object.assign(Object.assign({}, props), schema[field]), { error: error[field] !== undefined, onBlur: onBlur ? (event) => onBlur(field, event) : undefined, onChange: (value) => handleChange(field, value), onFocus: !BOOLEAN_TYPES.includes(type) ? (event) => handleFocus(field, event) : undefined }), (Object.keys(INPUT_TYPES).includes(type) || !props.options
                ? { onSubmitEditing: handleSubmit }
                : undefined)) : type === 'submit'
            ? Object.assign(Object.assign({}, props), { onPress: handleSubmit }) : undefined)));
    }))), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, error, others, schema]);
};
Form.displayName = 'Form';
export { Form };
