type TrackingEventBase = {};
type TrackingEvent = {};

const throwNotImplementedError = () => {
  throw new Error('Promocodes bridge has not been configured.');
};

export type PromocodesBridge = {
  getToken: () => Promise<string | undefined>;
  useFeatureFlags: () => Record<string, boolean>;
  appVersion: string;
  useAvoidKeyboard: () => { setAvoidKeyboard: (enabled: boolean) => {} };
  emitTrackingEvent: (e: TrackingEventBase) => void;
  proxy: (url: string) => string;
};

const state: { bridge: PromocodesBridge } = {
  bridge: {
    getToken: () => throwNotImplementedError(),
    useFeatureFlags: () => throwNotImplementedError(),
    appVersion: '',
    useAvoidKeyboard: () => throwNotImplementedError(),
    emitTrackingEvent: () => throwNotImplementedError(),
    proxy: () => throwNotImplementedError(),
  },
};

export const setPromocodesBridge = (newBridge: PromocodesBridge) => {
  state.bridge = newBridge;
};

export const getToken = () => state.bridge.getToken();
export const mustGetToken = async () => {
  const token = await getToken();
  if (!token) {
    throw new Error('An action required the user token and it is not available yet.');
  }
  return token;
};

export const useFeatureFlags = () => state.bridge.useFeatureFlags();
export const getAppVersion = () => state.bridge.appVersion;
export const useAvoidKeyboard = () => state.bridge.useAvoidKeyboard();
export const emitTrackingEvent = (e: TrackingEvent) => state.bridge.emitTrackingEvent(e);
export const proxy = (url: string) => state.bridge.proxy(url);
