var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { ButtonIcon } from '../../atoms';
import { ALIGN, COLOR, DISPLAY, POINTER, SPACE, styles } from '../../../hooks';
import { Icon, MOTION_TYPES, Motion, Text, VIEW_ROLES, View } from '../../primitives';
import { calcMotion } from './helpers';
import { NOTIFICATION_VARIANT } from './Notification.definition';
import { style } from './Notification.style';
const notificationIconName = {
    success: 'tick_fill',
    critical: 'critical',
    warning: 'warning',
    neutral: 'info',
};
const notificationIconColor = {
    success: COLOR.ICON_SUCCESS,
    critical: COLOR.ICON_ERROR,
    warning: COLOR.ICON_WARNING,
    neutral: COLOR.ICON_ON_PRIMARY,
};
const Notification = (_a) => {
    var { children, color, colorText, isVisible = false, motion = 'top', nativeID, timeoutClose, text, timing, onClose, variant = NOTIFICATION_VARIANT.NEUTRAL } = _a, others = __rest(_a, ["children", "color", "colorText", "isVisible", "motion", "nativeID", "timeoutClose", "text", "timing", "onClose", "variant"]);
    const [layout, setLayout] = useState();
    const handleLayout = (event) => {
        if (event)
            setLayout(event.nativeEvent.layout);
    };
    useEffect(() => {
        if (!timeoutClose || !onClose)
            return;
        let idTimeOut;
        if (isVisible)
            idTimeOut = setTimeout(onClose, timeoutClose);
        else
            clearTimeout(idTimeOut);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);
    const isVariantPromo = variant === NOTIFICATION_VARIANT.PROMO;
    const textColor = !isVariantPromo ? COLOR.TEXT_LIGHT : colorText ? colorText : COLOR.TEXT_PROMOTION;
    const backgroundColor = !isVariantPromo ? COLOR.BG_PRIMARY : color ? color : COLOR.BG_PROMOTION;
    return (React.createElement(Motion, { layout: layout, pointer: !isVisible ? POINTER.NONE : undefined, timing: timing, type: isVisible ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: motion ? calcMotion({ isVisible, motion }) : undefined, wide: true, role: VIEW_ROLES.aside, onLayout: handleLayout },
        React.createElement(View, Object.assign({}, others, { style: styles(others.style, style.container), backgroundColor: backgroundColor, nativeID: nativeID }),
            React.createElement(View, { style: style.notificationContent, testID: nativeID ? `${nativeID}-container` : undefined },
                !isVariantPromo && (React.createElement(Icon, { marginRight: SPACE.SPACE_2, name: notificationIconName[variant], color: notificationIconColor[variant] })),
                React.createElement(View, { style: style.textContainer },
                    text && (React.createElement(Text, { color: textColor, detail: !isVariantPromo, detailBold: isVariantPromo, display: DISPLAY.BLOCK, level: 2, style: style.text }, text)),
                    children),
                onClose && (React.createElement(View, null,
                    React.createElement(ButtonIcon, { alignSelf: ALIGN.CENTER, color: textColor, name: "close_small", style: style.button, onPress: onClose, testID: nativeID ? `${nativeID}-close` : undefined, small: true })))))));
};
Notification.displayName = 'Notification';
export { Notification };
