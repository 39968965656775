import { ToPromoCodeSuccess } from '@package/core/application/common/ToPromoCodeSuccess';
import { PromoCode, LocaliseSuccess, SuccessCodeType } from '@package/core/domain';

const getSuccessObject = (promoCode: PromoCode): LocaliseSuccess => {
  const isMultiBoxPromo = Number(promoCode.remainingApplicableOrders) > 1;
  const isPsFee = Boolean(promoCode.discountServicePct);

  if (isMultiBoxPromo) {
    return {
      id: SuccessCodeType.X_USES,
      params: { applicableBoxes: { value: String(promoCode.remainingApplicableOrders) } },
    };
  }

  if (isPsFee) return { id: SuccessCodeType.PS_FEE };

  return { id: SuccessCodeType.GENERAL };
};

export const ToPromoCodeSuccessBox = ToPromoCodeSuccess(getSuccessObject);
