const BANNED_PROPS = ['autoComplete', 'id', 'required', 'style'];
const TYPE_NUMBER = 'number';
var INPUT_TYPES;
(function (INPUT_TYPES) {
    INPUT_TYPES["text"] = "text";
    INPUT_TYPES["password"] = "password";
    INPUT_TYPES["email"] = "email";
    INPUT_TYPES["number"] = "number";
    INPUT_TYPES["search"] = "search";
    INPUT_TYPES["tel"] = "tel";
    INPUT_TYPES["time"] = "time";
    INPUT_TYPES["url"] = "url";
})(INPUT_TYPES || (INPUT_TYPES = {}));
const KEYBOARD_TYPES = {
    email: 'email-address',
    number: 'numeric',
    tel: 'number-pad',
    text: 'default',
    url: 'url',
};
var TEXT_CONTENT_TYPES;
(function (TEXT_CONTENT_TYPES) {
    TEXT_CONTENT_TYPES["email"] = "emailAddress";
    TEXT_CONTENT_TYPES["password"] = "password";
    TEXT_CONTENT_TYPES["tel"] = "telephoneNumber";
    TEXT_CONTENT_TYPES["url"] = "URL";
})(TEXT_CONTENT_TYPES || (TEXT_CONTENT_TYPES = {}));
export { BANNED_PROPS, INPUT_TYPES, KEYBOARD_TYPES, TYPE_NUMBER, TEXT_CONTENT_TYPES };
