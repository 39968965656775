import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        overflow: 'hidden',
    },
    content: {
        height: '100%',
        width: '100%',
    },
    text: {
        opacity: 0,
        position: 'absolute',
    },
});
