var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { ALIGN, COLOR, DISPLAY, FLEX_DIRECTION, POSITION, styles } from '../../../hooks';
import { TEXT_ROLES, Text, VIEW_ROLES, View } from '../../primitives';
import { style as buildStyle } from './Step.style';
const Step = (_a) => {
    var { active, checked, first, forceActive, horizontal = true, last, index = 0, text } = _a, others = __rest(_a, ["active", "checked", "first", "forceActive", "horizontal", "last", "index", "text"]);
    const style = buildStyle(horizontal);
    const indexNumber = (index + 1).toString();
    return (React.createElement(View, Object.assign({}, others, { alignItems: ALIGN.CENTER, flexDirection: horizontal ? FLEX_DIRECTION.COLUMN : FLEX_DIRECTION.ROW, role: VIEW_ROLES.li, style: styles(style.step, horizontal ? style.horizontal : style.vertical, others.style), wide: horizontal }),
        React.createElement(View, { style: style.track, wide: horizontal },
            React.createElement(View, { borderColor: active ? COLOR.BG_PRIMARY : COLOR.BG_OPACITY_LIGHT, style: styles(style.line, style.start, first && style.lineTransparent) }),
            React.createElement(View, { borderColor: active ? COLOR.BG_PRIMARY : COLOR.BG_OPACITY_LIGHT, display: DISPLAY.BLOCK, style: style.circle },
                React.createElement(Text, { align: ALIGN.CENTER, color: !(active || checked) ? COLOR.TEXT_OPACITY_LIGHT : COLOR.TEXT, detail: true, style: style.textIndexNumber }, indexNumber)),
            React.createElement(View, { borderColor: checked || forceActive ? COLOR.BG_PRIMARY : COLOR.BG_OPACITY_LIGHT, style: styles(style.line, style.end, last && style.lineTransparent) })),
        React.createElement(Text, { align: horizontal ? ALIGN.CENTER : undefined, color: !(active || checked) ? COLOR.TEXT_OPACITY_LIGHT : COLOR.TEXT, detail: horizontal, display: DISPLAY.BLOCK, position: horizontal ? POSITION.ABSOLUTE : POSITION.RELATIVE, role: TEXT_ROLES.strong, style: style.text }, text)));
};
Step.displayName = 'Step';
export { Step };
