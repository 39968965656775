var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UserRepositoryImpl } from '../../infrastructure/domain/userRepositoryImpl/UserRepositoryImpl';
const userRepo = UserRepositoryImpl.getInstance();
const fetchEnabledOfferings = (auth) => __awaiter(void 0, void 0, void 0, function* () {
    const accessesPromises = [
        userRepo.hasCompleteYourLookAccess(auth).catch(() => false),
        userRepo.hasCategoriesAccess(auth).catch(() => false),
        userRepo.hasSalesAccess(auth).catch(() => false),
        userRepo.hasTrendingForYouAccess(auth).catch(() => false),
    ];
    const data = yield Promise.all(accessesPromises);
    return {
        completeYourLook: Boolean(data[0]),
        categories: Boolean(data[1]),
        sales: Boolean(data[2]),
        trendingForYou: Boolean(data[3]),
    };
});
export default fetchEnabledOfferings;
