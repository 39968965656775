import { PromoCode, UserSubscription, OrderId } from '@package/core/domain';

interface ValidatePromoCodeBody {
  device: string;
  promocode: string;
  service: string;
  first_order: boolean;
  apply_order: boolean;
  user_subscription: UserSubscription;
  order_id?: OrderId;
}

interface ValidatePromoCodeResponse {
  name: string;
  discount_amount: number;
  discount_pct: number;
  discount_service_pct: number;
  remaining_applicable_orders: number;
  applicable_orders: number;
}

export const promoCodeToApi = (promoCode: PromoCode): ValidatePromoCodeBody => ({
  device: promoCode.device ?? '',
  promocode: promoCode.name.toUpperCase(),
  service: promoCode.service ?? '',
  first_order: promoCode.isFirstOrder || false,
  apply_order: Boolean(promoCode.orderId),
  user_subscription: promoCode.userSubscription || UserSubscription.OCCASIONAL,
  order_id: promoCode.orderId,
});

export const apiToPromoCode = (promocode: ValidatePromoCodeResponse): PromoCode => ({
  name: promocode.name,
  discountAmount: promocode.discount_amount,
  discountPct: promocode.discount_pct,
  discountServicePct: promocode.discount_service_pct,
  remainingApplicableOrders: promocode.remaining_applicable_orders,
  applicableOrders: promocode.applicable_orders,
});
