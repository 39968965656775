import {
  GetLocaliseError,
  LocaliseMapper,
  ErrorCodeType,
  LocaliseMap,
  GetLocaliseSuccess,
  Localise,
  SuccessCodeType,
  LocaliseParams,
} from '@package/core/domain';

export const localizationMapper = (localiseMap: LocaliseMap): LocaliseMapper => {
  const { localiseErrorMap, localiseSuccessMap } = localiseMap;

  const parseParams = (params: LocaliseParams, localiseMap: Record<string, string>): LocaliseParams => {
    return Object.entries(params).reduce((previous, current) => {
      const [key, element] = current;

      if (element.link) {
        return { ...previous, [key]: { ...element, value: localiseMap[element.value] } };
      }

      return { ...previous, [key]: element };
    }, {});
  };

  const getLocaliseError: GetLocaliseError = (
    errorCodeType: ErrorCodeType,
    params?: LocaliseParams,
  ): Localise | undefined => {
    if (localiseErrorMap[errorCodeType] === undefined) return;
    const parsedParams = params ? parseParams(params, localiseErrorMap) : undefined;
    return { id: localiseErrorMap[errorCodeType] as string, params: parsedParams };
  };

  const getLocaliseSuccess: GetLocaliseSuccess = (
    successCodeType: SuccessCodeType,
    params?: LocaliseParams,
  ): Localise => {
    return {
      id: localiseSuccessMap[successCodeType] as string,
      params: params ? parseParams(params, localiseSuccessMap) : undefined,
    };
  };

  return {
    getLocaliseError,
    getLocaliseSuccess,
  };
};
