export class RequestError extends Error {
  code;
  payload;

  constructor({ message = 'Unknown error', code, payload, error } = {}) {
    const parsedMessage = `${code ? `Error ${code}: ` : ''}${message}`;
    super(parsedMessage, { cause: error instanceof Error ? error : undefined });
    this.name = 'RequestError';
    this.code = code;
    this.payload = payload;
  }
}
