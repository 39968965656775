var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '@lookiero/data-sources';
import { COMPLETE_YOUR_LOOK_BASE_URL, CATEGORIES_BASE_URL, ONBOARDING_BASE_URL, SALES_BASE_URL, USERS_BASE_URL, TRENDING_FOR_YOU_BASE_URL, } from '../../ui/shared/config';
export class UserRepositoryImpl {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { }
    static getInstance() {
        if (!UserRepositoryImpl.instance) {
            UserRepositoryImpl.instance = new UserRepositoryImpl();
        }
        return UserRepositoryImpl.instance;
    }
    hasCompleteYourLookAccess(auth) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { active = false } = yield request({
                    endpoint: `${COMPLETE_YOUR_LOOK_BASE_URL}/has-access`,
                    headers: { Authorization: auth },
                    method: 'POST',
                });
                return active;
            }
            catch (error) {
                return Promise.reject(error);
            }
        });
    }
    hasCategoriesAccess(auth) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { active = false } = yield request({
                    endpoint: `${CATEGORIES_BASE_URL}/has-access`,
                    headers: { Authorization: auth },
                    method: 'POST',
                });
                return active;
            }
            catch (error) {
                throw new Error('hasCategoriesAccess');
            }
        });
    }
    hasSalesAccess(auth) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { active = false } = yield request({
                    endpoint: `${SALES_BASE_URL}/has-access`,
                    headers: { Authorization: auth },
                    method: 'POST',
                });
                return active;
            }
            catch (error) {
                console.log(error);
                throw new Error('hasSalesAccess');
            }
        });
    }
    hasTrendingForYouAccess(auth) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { active = false } = yield request({
                    endpoint: `${TRENDING_FOR_YOU_BASE_URL}/has-access`,
                    headers: { Authorization: auth },
                    method: 'POST',
                });
                return active;
            }
            catch (error) {
                console.log(error);
                throw new Error('hasTrendingForYouAccess');
            }
        });
    }
    hasPurchase(auth) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { active = false } = yield request({
                    endpoint: `${USERS_BASE_URL}/has-purchase`,
                    headers: { Authorization: auth },
                });
                return active;
            }
            catch (error) {
                console.log(error);
                throw new Error('hasPurchase');
            }
        });
    }
    logLastAccess(auth) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield request({
                    endpoint: `${ONBOARDING_BASE_URL}/log-last-access`,
                    headers: { Authorization: auth },
                    method: 'PUT',
                });
            }
            catch (error) {
                console.log(error);
                return Promise.reject('logLastAccess');
            }
        });
    }
}
