const AVAILABLE_LOCALES = ['es-ES', 'en-GB', 'fr-FR', 'it-IT', 'pt-PT', 'fr-BE', 'de-DE'];

const DEFAULT_CURRENCY = 'EUR';

const DEFAULT_DICTIONARY = 'user-area-front';

const DEFAULT_DICTIONARIES = [DEFAULT_DICTIONARY];

const DEFAULT_LOCALE = 'en-GB';

const STORAGE_KEY = 'lookiero:locale:dictionary';

const VERSION = 'locale.version';

const VERSION_EXPIRE = 60 * 1000;

export {
  AVAILABLE_LOCALES,
  DEFAULT_CURRENCY,
  DEFAULT_DICTIONARY,
  DEFAULT_DICTIONARIES,
  DEFAULT_LOCALE,
  STORAGE_KEY,
  VERSION,
  VERSION_EXPIRE,
};
