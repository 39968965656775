import { INPUT_TYPES } from '../Input.definition';
const parseNumber = (value) => !isNaN(parseFloat(String(value))) ? parseFloat(String(value)) : undefined;
const parseText = (value = '', trimSpaces = false) => (trimSpaces ? value.trim() : value).toString();
export const parseValue = (value, type, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
{ trimSpaces = false } = {}) => {
    if (typeof value === 'undefined' || value.toString().trim().length === 0)
        return undefined;
    const stringValue = String(value);
    return type === INPUT_TYPES.number ? parseNumber(stringValue) : parseText(stringValue, trimSpaces);
};
