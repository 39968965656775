import {
  ErrorCodeType,
  LocaliseErrorMap,
  LocaliseSuccessMap,
  SuccessCodeType,
  LocaliseMap,
} from '@package/core/domain';

const localiseErrorMap: LocaliseErrorMap = {
  [ErrorCodeType.PromoLimit]: 'account.myLKpay.promocode_toast_error_limit',
  [ErrorCodeType.PromoFirstOrder]: 'account.myLKpay.promocode_toast_error_firstbox',
  [ErrorCodeType.PromoExpired]: 'account.myLKpay.promocode_toast_error_noapply',
  [ErrorCodeType.PromoOneCode]: 'account.promocode_toast_error_1code',
  [ErrorCodeType.PromoNotApplyToService]: 'account.myLKpay.promocode_toast_error_justbox',
  [ErrorCodeType.Unexpected]: 'account.myLKpay.promocode_toast_error_null',
};

const localiseSuccessMap: LocaliseSuccessMap = {
  [SuccessCodeType.GENERAL]: 'myLK.shipAndPay.promocode_toast_success_general',
};

export const ShopLocoMap: LocaliseMap = {
  localiseErrorMap,
  localiseSuccessMap,
};
