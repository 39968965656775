import {
  ErrorCodeType,
  LocaliseErrorMap,
  LocaliseSuccessMap,
  SuccessCodeType,
  LocaliseMap,
} from '@package/core/domain';

const localiseErrorMap: LocaliseErrorMap = {
  [ErrorCodeType.PromoLimit]: 'account.myLKpay.promocode_toast_error_limit',
  [ErrorCodeType.PromoFirstOrder]: 'account.myLKpay.promocode_toast_error_firstbox',
  [ErrorCodeType.PromoExpired]: 'account.myLKpay.promocode_toast_error_noapply',
  [ErrorCodeType.PromoOneCode]: 'account.promocode_toast_error_1code',
  [ErrorCodeType.PromoForSubs]: 'account.myLKpay.promocode_toast_error_subs',
  [ErrorCodeType.PromoForOnDemand]: 'account.myLKpay.promocode_toast_error_ond',
  [ErrorCodeType.PromoNotApplyToService]: 'account.myLKpay.promocode_toast_error_justshop',
  [ErrorCodeType.RedirectToService]: 'account.myLKpay.promocode_toast_error_justshop2',
  [ErrorCodeType.Unexpected]: 'account.myLKpay.promocode_toast_error_null',
};

const localiseSuccessMap: LocaliseSuccessMap = {
  [SuccessCodeType.X_USES]: 'account.myLKpay.promocode_toast_success_Xuses',
  [SuccessCodeType.PS_FEE]: 'account.myLKpay.promocode_toast_success',
  [SuccessCodeType.GENERAL]: 'myLK.shipAndPay.promocode_toast_success_general',
};

export const BoxLocoMap: LocaliseMap = {
  localiseErrorMap,
  localiseSuccessMap,
};
