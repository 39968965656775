var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { View } from '../../primitives';
import { Thumbnail } from '../Thumbnail';
const Slide = (_a) => {
    var { children, height = 320, src, width = 256 } = _a, others = __rest(_a, ["children", "height", "src", "width"]);
    return (React.createElement(View, Object.assign({}, others),
        React.createElement(Thumbnail, { src: src, height: height, width: width }),
        children));
};
Slide.displayName = 'Slide';
export { Slide };
