var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, Fragment, isValidElement } from 'react';
import { COLOR, useDevice } from '../../../hooks';
import { VIEW_ROLES, View } from '../../primitives';
import { style } from './Footer.style';
import { FooterItem } from './FooterItem';
export const Footer = (_a) => {
    var { children, color = COLOR.BG_BASE, container: Container = Fragment, onChange, value } = _a, others = __rest(_a, ["children", "color", "container", "onChange", "value"]);
    const { screen } = useDevice();
    return (React.createElement(View, Object.assign({}, others, { backgroundColor: color, role: VIEW_ROLES.nav, wide: true }),
        React.createElement(Container, null,
            React.createElement(View, { style: [style.footer, screen.L && style.footerDesktop] }, Children.toArray(children).map((child) => {
                if (!isValidElement(child))
                    return null;
                return (React.createElement(FooterItem, Object.assign({ key: child.props.value }, child.props, { active: value ? value.includes(child.props.value) : false, onPress: onChange ? () => onChange(child.props.value) : undefined })));
            })))));
};
Footer.displayName = 'Footer';
