import { attributionTracker } from './attributionTracker';
import { campaignTracker } from './campaignTracker';
import { sendTrackerModel } from './sendTrackerModel';
import { userTracker } from './userTracker';

export * from './attributionTracker';
export * from './campaignTracker';
export * from './userTracker';
export * from './helpers';

export const initTracking = () => {
  attributionTracker();
  campaignTracker();

  return {
    sendTrackerModel,
    userTracker,
  };
};
