import { CommonApplication } from '@package/core/application/common';
import { ApiError, OrderId, PromoCode } from '@package/core/domain';
import { QUERY_OPTIONS } from '@package/ui/providers/ReactQuery';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

interface UseGetPromoFromOrderParams {
  orderId?: OrderId;
}

type Data = PromoCode | undefined;
type Error = ApiError;

export const useGetPromoFromOrder = (
  { orderId }: UseGetPromoFromOrderParams = {},
  options: UseQueryOptions<Data, Error> = {},
) => {
  return useQuery<Data, Error>(
    ['promoFromOrder', orderId],
    async () => (orderId ? CommonApplication.getPromoFromOrder(orderId) : undefined),
    { ...QUERY_OPTIONS, ...options },
  );
};
