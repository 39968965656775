import { LocaliseMapper, PromoCode, LocaliseSuccess } from '@package/core/domain';

export const ToPromoCodeSuccess =
  (getSuccessObject: (promoCode: PromoCode) => LocaliseSuccess) =>
  (localiseMapper: LocaliseMapper) =>
  (promoCode: PromoCode): PromoCode => {
    const { id, params } = getSuccessObject(promoCode);

    return {
      ...promoCode,
      localise: localiseMapper.getLocaliseSuccess(id, params),
    };
  };
