import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        borderRadius: '$borderRadius2',
        borderStyle: '$borderStyle',
        borderWidth: '$borderWidth2',
        height: '$inputOptionSize',
        width: '$inputOptionSize',
    },
    radio: {
        borderRadius: '$borderRadiusFull',
    },
});
