var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createElement } from 'react';
import { COLOR, FLEX_DIRECTION, POINTER, POSITION, useBanStylerProps } from '../../../hooks';
import { Icon, MOTION_TYPES, Motion, SWITCH_VARIANT, Switch, View } from '../../primitives';
import { style } from './InputOption.style';
export const InputOption = (_a) => {
    var { checked = false, children, disabled = false, id, name, onChange, type = SWITCH_VARIANT.RADIO, value } = _a, others = __rest(_a, ["checked", "children", "disabled", "id", "name", "onChange", "type", "value"]);
    return (React.createElement(View, Object.assign({}, others, { flexDirection: FLEX_DIRECTION.ROW }),
        React.createElement(View, { style: style.input },
            createElement(Switch, Object.assign(Object.assign({}, useBanStylerProps(Object.assign(Object.assign({}, others), { testID: undefined }))), { checked,
                disabled,
                id,
                name,
                value, variant: type, onChange })),
            React.createElement(Motion, { pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: style.icon, type: checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { opacity: checked ? 1 : 0, scale: checked ? 1 : 0 } },
                React.createElement(Icon, { color: disabled ? COLOR.ICON_DISABLED : COLOR.ICON, name: "tick" }))),
        children && (React.createElement(View, { style: style.childrenView, wide: true }, children))));
};
InputOption.displayName = 'InputOption';
