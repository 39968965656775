import { AsyncStorage, LocalAdapter, request } from '@lookiero/data-sources';

import { DEFAULT_DICTIONARIES, DEFAULT_LOCALE, VERSION, VERSION_EXPIRE } from '../Locale.definition';
import { getCachedDictionary } from './getCachedDictionary';
import { getCacheKey } from './getCacheKey';

export const getDictionary = async ({
  keys = DEFAULT_DICTIONARIES,
  locale = DEFAULT_LOCALE,
  onError = () => {},
} = {}) => {
  const [language, country] = locale.split('-');
  const timestamp = new Date().getTime();
  const cachedDictionary = await getCachedDictionary({ keys, locale });
  const { [VERSION]: cachedVersion } = cachedDictionary || {};
  let dictionary = cachedDictionary;

  if (!cachedVersion || timestamp - cachedVersion > VERSION_EXPIRE) {
    const projectFilter = keys.map((key) => `projectFilter=${key}`).join('&');
    dictionary = await request({
      endpoint: `/api/v2/translations/${language}/${country}?${projectFilter}`,
    }).catch((error) => {
      onError(new Error(`Failed to get translations ${locale}, error: ${error.message}`));
    });

    if (dictionary) {
      const timestamp = new Date().getTime();
      const storage = await new AsyncStorage({ adapter: LocalAdapter });
      await storage.set(getCacheKey({ keys, locale }), { ...dictionary, [VERSION]: timestamp });
    } else {
      dictionary = cachedDictionary;
    }
  }

  return dictionary;
};
