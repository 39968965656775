import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    input: {
        height: '$iconSize',
        width: '$iconSize',
        position: 'relative',
    },
    icon: {
        top: '$space025 * -1',
        left: '$space025 * -1',
    },
    childrenView: {
        flex: 1,
    },
});
