import { shell, textToJson } from '@helpers';

import { getUrl } from './modules';
import { HEADERS } from './request.config';
import { RequestError } from './RequestError';

const SHELL_TITLE = 'request()';

const AbortControllerProxy = () =>
  typeof AbortController === 'undefined'
    ? {
        abort: () => {},
        signal: undefined,
      }
    : new AbortController();

export const request = async ({ endpoint, headers, hostname, method = 'GET', timeout, ...props } = {}) => {
  const controller = new AbortControllerProxy();

  if (timeout) setTimeout(() => controller.abort(), timeout);

  return new Promise((resolve, reject) => {
    const url = getUrl({ endpoint, hostname });

    fetch(url, {
      headers: { ...HEADERS, ...headers },
      method,
      signal: timeout ? controller.signal : undefined,
      ...(['DELETE', 'POST', 'PUT'].includes(method) ? { body: JSON.stringify(props) } : props),
    })
      .then(async (response) => {
        const text = await response.text();

        const json = text.length ? textToJson(text) : undefined;

        if (response.status >= 400) {
          shell.error(SHELL_TITLE, url, { response });
          reject(
            new RequestError({
              code: response.status,
              message: json?.message,
              payload: json,
            }),
          );
        } else {
          shell.info(SHELL_TITLE, url, { response: json });
          resolve(json);
        }
      })
      .catch((error = {}) => {
        const { message = 'Something wrong happened. Try again.', response } = error;
        shell.error(SHELL_TITLE, url, { response, message });
        reject(new RequestError({ code: response?.status, message, error }));
      });
  });
};
