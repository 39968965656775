import { ApiError } from '@package/core/domain';
import { getErrorCodeType } from '@package/core/domain/ApiError';

export interface BackendError extends Error {
  error_code: string;
  error_message: string;
  timestamp: number;
}

export const apiToError = (error: BackendError): ApiError => ({
  rawError: error,
  errorCode: error.error_code,
  errorMessage: error.error_message,
  errorCodeType: getErrorCodeType(error.error_code),
});
