var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { ALIGN, FLEX_DIRECTION, SIZE } from '../../../../../hooks';
import { Input, TEXT_ROLES, Text, View } from '../../../../primitives';
import { style } from './Field.style';
const Field = forwardRef((_a, ref) => {
    var { color, label } = _a, others = __rest(_a, ["color", "label"]);
    return (React.createElement(View, { alignItems: ALIGN.CENTER, flex: SIZE.XS, flexDirection: FLEX_DIRECTION.ROW },
        React.createElement(Input, Object.assign({}, others, { ref: ref })),
        React.createElement(Text, { color: color, detail: true, level: 1, role: TEXT_ROLES.small, style: style.label }, label)));
});
Field.displayName = 'InputFieldFieldSetField';
export { Field };
