var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useState } from 'react';
import { POSITION } from '../../../../../hooks';
import { INPUT_TYPES, Input } from '../../../../primitives';
import { ButtonIcon } from '../../../ButtonIcon/ButtonIcon';
import { style } from '../../InputField.style';
const Password = forwardRef((_a, ref) => {
    var { color } = _a, others = __rest(_a, ["color"]);
    const [visible, setVisible] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(Input, Object.assign({}, others, { autoComplete: "off", type: !visible ? INPUT_TYPES.password : INPUT_TYPES.text, ref: ref })),
        React.createElement(ButtonIcon, { "aria-hidden": "true", tabIndex: -1, color: color, disabled: others.disabled, name: visible ? 'eye_open' : 'eye_close', position: POSITION.ABSOLUTE, small: true, style: style.icon, onPress: () => setVisible(!visible) })));
});
Password.displayName = 'InputFieldPassword';
export { Password };
