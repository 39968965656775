import { Platform } from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    input: Object.assign({ color: '$colorText', flex: 0, height: '$inputSize' }, Platform.select({
        web: {
            outlineWidth: 0,
            height: undefined,
        },
    })),
    disabled: {
        color: '$colorTextDisabled',
    },
    multiline: Object.assign(Object.assign({}, Platform.select({
        web: {
            flex: 'unset',
        },
    })), { paddingTop: '$space4', paddingBottom: '$space4', textAlignVertical: 'top' }),
});
