export enum ErrorCodeType {
  PromoLimit = 'PROMO_CODE_REACHED_LIMIT_USES',
  PromoFirstOrder = 'PROMO_CODE_FOR_FIRST_ORDER',
  PromoExpired = 'PROMO_CODE_EXPIRED',
  PromoOneCode = 'PROMO_CODE_ONE_CODE',
  PromoForSubs = 'PROMO_CODE_FOR_SUBS',
  PromoForOnDemand = 'PROMO_CODE_FOR_ON_DEMAND',
  PromoNotApplyToService = 'PROMO_NOT_APPLY_TO_SERVICE',
  RedirectToService = 'REDIRECT_TO_SERVICE',
  Unexpected = 'UNEXPECTED_ERROR_CODE',
}

export type ErrorCodeMap = Record<string, string[]>;

const ERROR_CODE_MAP: ErrorCodeMap = {
  [ErrorCodeType.PromoLimit]: ['PSA-005'],
  [ErrorCodeType.PromoFirstOrder]: ['PSA-006'],
  [ErrorCodeType.PromoExpired]: ['PSA-010', 'PSA-004'],
  [ErrorCodeType.PromoOneCode]: ['PSA-011'],
  [ErrorCodeType.PromoForSubs]: ['PSA-007'],
  [ErrorCodeType.PromoNotApplyToService]: ['PSA-025'],
  [ErrorCodeType.PromoForOnDemand]: ['PSA-026'],
};

export interface ApiError {
  rawError: Error;
  errorCode: string;
  errorMessage: string;
  errorCodeType: ErrorCodeType;
}

export const getErrorCodeType = (errorCode: string | undefined): ErrorCodeType => {
  const result = errorCode ? Object.entries(ERROR_CODE_MAP).find(([, value]) => value.includes(errorCode)) : undefined;
  return result ? (result[0] as ErrorCodeType) : ErrorCodeType.Unexpected;
};
