import { ApiRepository } from '@package/core/infrastructure/apiRepository';

import { GetAutoApplicablePromo } from '../common/GetAutoApplicablePromo';
import { GetPromoFromOrder } from './GetPromoFromOrder';
import { ValidatePromoCode } from './ValidatePromoCode';

export { ToPromoCodeError } from './ToPromoCodeError';
export { ToPromoCodeSuccess } from './ToPromoCodeSuccess';

export const CommonApplication = {
  getPromoFromOrder: GetPromoFromOrder(ApiRepository),
  validatePromoCode: ValidatePromoCode(ApiRepository),
  getAutoApplicablePromo: GetAutoApplicablePromo(ApiRepository),
};
