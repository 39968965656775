var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Children, cloneElement, useState } from 'react';
import { ButtonIcon } from '../../atoms';
import { capitalize } from '../../../helpers';
import { ALIGN, COLOR, FLEX_DIRECTION, POINTER, POSITION, SIZE, SPACE, styles, useId } from '../../../hooks';
import { Icon, MOTION_TIMING, Motion, TEXT_ROLES, Text, VIEW_ROLES, View } from '../../primitives';
import { style } from './ToolTip.style';
export const ToolTip = (_a) => {
    var { align = ALIGN.TOP, alignArrow = ALIGN.CENTER, color = COLOR.BG_BASE, colorText = COLOR.TEXT, shadow, text, title, children, icon, isVisible = true } = _a, others = __rest(_a, ["align", "alignArrow", "color", "colorText", "shadow", "text", "title", "children", "icon", "isVisible"]);
    const id = useId();
    const [visible, setVisible] = useState(isVisible);
    const toggleVisible = () => {
        setVisible(!visible);
    };
    return (React.createElement(View, Object.assign({}, others, { layer: SIZE.M, style: styles(others.style) }),
        React.createElement(Motion, { pointer: !visible ? POINTER.NONE : undefined, position: POSITION.ABSOLUTE, role: VIEW_ROLES.section, style: styles(style.toolTip, style[align], others.style), 
            // @ts-expect-error We sgould review this
            tabIndex: 0, timing: MOTION_TIMING.SPRING, value: {
                opacity: visible ? 1 : 0,
                translateY: visible ? `${align === 'top' ? '-' : ''}1rem` : 0,
            }, wide: true },
            React.createElement(View, { backgroundColor: color, style: [style.container, shadow && style.containerShadow], wide: true },
                React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW },
                    icon && React.createElement(Icon, { name: icon, color: colorText, marginRight: SPACE.SPACE_2 }),
                    React.createElement(View, { flex: SIZE.XS, flexDirection: FLEX_DIRECTION.COLUMN },
                        title && (React.createElement(Text, { role: TEXT_ROLES.strong, color: colorText, detailBold: true, level: 1, marginBottom: SPACE.SPACE_05 }, title)),
                        React.createElement(Text, { color: colorText, detail: true, level: 2, marginRight: SPACE.SPACE_2 }, text)),
                    React.createElement(ButtonIcon, { color: colorText, layer: SIZE.L, name: "close_small", style: style.buttonIcon, onPress: toggleVisible, small: true }))),
            React.createElement(View, { backgroundColor: color, pointer: POINTER.NONE, position: POSITION.ABSOLUTE, style: styles(style.arrow, style[`arrow${capitalize(align)}`], style[`arrow${capitalize(alignArrow)}`]) })),
        Children.map(children, (child) => cloneElement(child, {
            onPress: toggleVisible,
            'aria-describedby': id,
        }))));
};
ToolTip.displayName = 'ToolTip';
