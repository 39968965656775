import { ToPromoCodeError } from '@package/core/application/common/ToPromoCodeError';
import { ApiError, ErrorCodeType, LocaliseError, LocaliseParams, ROUTE_KEY } from '@package/core/domain';

const getParams = (error: ApiError): LocaliseParams | undefined => {
  if (error.errorCodeType === ErrorCodeType.PromoNotApplyToService) {
    return { link: { value: ErrorCodeType.RedirectToService, link: ROUTE_KEY.SHOP } };
  }
  return;
};

const getErrorObject = (error: ApiError): LocaliseError => {
  return { id: error.errorCodeType, params: getParams(error) };
};

export const ToPromoCodeErrorBox = ToPromoCodeError(getErrorObject);
