import { PromoCode, OrderId, Repository } from '@package/core/domain';

import { apiToError, BackendError } from './dto/Error';
import { apiToPromoCode as getAutoApplicablePromoAdapter } from './dto/GetAutoApplicablePromo';
import { apiToPromoCode as getPromoFromOrderAdapter } from './dto/GetPromoFromOrder';
import {
  promoCodeToApi as validatePromoCodeToApi,
  apiToPromoCode as validatePromoCodeAdapter,
} from './dto/ValidatePromoCode';
import { HttpClient } from './HttpClient';

const BASE_API = '/api';

const rejectApiError = (err: BackendError) => Promise.reject(apiToError(err));

const validatePromoCode = async (promoCode: PromoCode): Promise<PromoCode> =>
  HttpClient.post(`${BASE_API}/v1/promotions/validation`, validatePromoCodeToApi(promoCode))
    .then(validatePromoCodeAdapter)
    .catch(rejectApiError);

const getPromoFromOrder = async (orderId: OrderId): Promise<PromoCode> =>
  HttpClient.get(`${BASE_API}/v1/promotions/orders/${orderId}`).then(getPromoFromOrderAdapter).catch(rejectApiError);

const getAutoApplicablePromo = async (): Promise<PromoCode> =>
  HttpClient.get(`${BASE_API}/v1/promotions/auto-applicable`).then(getAutoApplicablePromoAdapter).catch(rejectApiError);

const ApiRepository: Repository = {
  validatePromoCode,
  getPromoFromOrder,
  getAutoApplicablePromo,
};

export { ApiRepository };
