import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '../Locale.definition';
import { FORMATS } from './formatCurrency.definition';

export const formatCurrency = ({ currency = DEFAULT_CURRENCY, locale = DEFAULT_LOCALE, value = 0 } = {}) => {
  const { minimumFractionDigits } = FORMATS[locale] || FORMATS[DEFAULT_LOCALE];

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
  });

  return formatter.format(value);
};
