import { CommonApplication } from '@package/core/application/common';
import { ApiError, PromoCode } from '@package/core/domain';
import { QUERY_OPTIONS } from '@package/ui/providers/ReactQuery';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type Data = PromoCode;
type Error = ApiError;

export const useGetAutoApplicablePromo = (options: UseQueryOptions<Data, Error> = {}) => {
  return useQuery<Data, Error>(['autoApplicablePromo'], CommonApplication.getAutoApplicablePromo, {
    ...QUERY_OPTIONS,
    ...options,
  });
};
