import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        borderRadius: '$borderRadius3',
        shadowColor: '$shadowColor',
        shadowOffset: '$shadowOffset',
        shadowOpacity: '$shadowOpacityM',
        shadowRadius: '$shadowRadius',
        elevation: '$shadowElevation',
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: '$space3',
        paddingVertical: '$space4',
        position: 'relative',
    },
    textContainer: {
        flexDirection: 'column',
        flex: 1,
        marginRight: '$space2',
    },
    text: {
        paddingTop: () => StyleSheet.value('$space1 + $space025'),
    },
    button: {
        padding: '$space1',
    },
});
