import { ApplicationReturnType } from '@package/core/application';
import React from 'react';

type ContextValue = ApplicationReturnType;

const StrategyContext = React.createContext<ContextValue>({} as ApplicationReturnType);

export const StrategyProvider: React.FC<{
  application: ContextValue;
  children: React.ReactNode;
}> = ({ application, children }) => {
  return <StrategyContext.Provider value={application}>{children}</StrategyContext.Provider>;
};

export const useStrategy = () => {
  return React.useContext(StrategyContext);
};
