import { CommonApplication } from '@package/core/application/common';
import { PromoCode, ApiError } from '@package/core/domain';
import { QUERY_OPTIONS } from '@package/ui/providers/ReactQuery';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type Data = PromoCode;
type Error = ApiError;

export const useValidatePromo = (options: UseMutationOptions<Data, Error, Data> = {}) => {
  return useMutation<Data, Error, Data>(CommonApplication.validatePromoCode, {
    ...QUERY_OPTIONS,
    ...options,
  });
};
