import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    footer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'center',
        paddingHorizontal: '$space2',
        paddingBottom: '$space1',
        paddingTop: '$space2',
    },
    footerDesktop: {
        alignItems: 'end',
        paddingHorizontal: 0,
        padding: 0,
    },
});
