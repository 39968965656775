import { useRef, useInsertionEffect, useCallback } from 'react';

/**
 * polyfill for experimental React feature useEffectEvent
 * https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 * **/
export const useEffectEvent = <T extends Function>(fn: T): T => {
  const ref = useRef(fn);

  useInsertionEffect(() => {
    ref.current = fn;
  }, [fn]);

  // @ts-expect-error cannot type useCallback with fn type
  return useCallback((...args: any[]): any => {
    return ref.current(...args);
  }, []);
};
