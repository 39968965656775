const DEFAULT_FORMAT = {
  currency: 'EUR',
  decimalAnchor: ',',
  minimumFractionDigits: 2,
  symbolAnchor: ' ',
  thousandsAnchor: '',
};

const FORMATS = {
  'es-ES': DEFAULT_FORMAT,
  'en-GB': {
    currency: 'GBP',
    decimalAnchor: '.',
    imperial: true,
    symbolAnchor: '',
    thousandsAnchor: ',',
  },
  'fr-FR': {
    ...DEFAULT_FORMAT,
    thousandsAnchor: ' ',
  },
  'it-IT': {
    ...DEFAULT_FORMAT,
    thousandsAnchor: '.',
  },
  'pt-PT': DEFAULT_FORMAT,
  'fr-BE': {
    ...DEFAULT_FORMAT,
    thousandsAnchor: ' ',
  },
  'de-DE': {
    ...DEFAULT_FORMAT,
    thousandsAnchor: '.',
  },
};

const SYMBOLS = {
  EUR: {
    default: '€',
  },
  GBP: {
    default: '£',
    'es-ES': 'GBP',
    'fr-FR': '£GB',
    'fr-BE': '£GB',
  },
};

export { FORMATS, SYMBOLS };
