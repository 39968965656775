var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { COLOR, SPACE, styles } from '../../../hooks';
import { Text, VIEW_ROLES, View } from '../../primitives';
import { style } from './TableRow.style';
const TableRow = (_a) => {
    var { dataSource, schema } = _a, others = __rest(_a, ["dataSource", "schema"]);
    const isHead = dataSource === undefined;
    const as = isHead ? 'th' : 'td';
    const fields = Object.keys(schema);
    return (React.createElement(View, Object.assign({}, others, { role: VIEW_ROLES.tr, style: styles(style.tableRow, others.style) }), fields.map((field, index) => (React.createElement(View, { as: as, key: field, paddingRight: index < fields.length - 1 ? SPACE.SPACE_4 : undefined, style: [style.row, style[as]] },
        React.createElement(Text, { color: isHead ? COLOR.TEXT_MEDIUM : COLOR.TEXT, detail: true }, isHead ? schema[field] : dataSource[field]))))));
};
TableRow.displayName = 'TableRow';
export { TableRow };
