import { View, Theme } from '@lookiero/aurora';
import React from 'react';
import { Path, Svg } from 'react-native-svg';

import { style } from './TickIcon.styles';

const { iconSize } = Theme.get();

const TickIcon = () => {
  return (
    <View testID="valid-promo-icon" style={style.container}>
      <Svg width={iconSize} height={iconSize} viewBox={`0 0 ${iconSize} ${iconSize}`} fill="none">
        <Path
          d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM17.0706 8.24284C16.6801 7.85232 16.0469 7.85232 15.6564 8.24284L10.3635 13.5357L8.34334 11.5156C7.95281 11.125 7.31965 11.125 6.92912 11.5156C6.5386 11.9061 6.5386 12.5393 6.92912 12.9298L9.6564 15.6571C10.0469 16.0476 10.6801 16.0476 11.0706 15.6571L17.0706 9.65706C17.4611 9.26653 17.4611 8.63337 17.0706 8.24284Z"
          fill="#3DA490"
        />
      </Svg>
    </View>
  );
};

export { TickIcon };
