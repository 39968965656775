import { PromoCode, PromoType } from '@package/core/domain';

export interface PromoCodeResponse {
  id: number;
  name: string;
  remaining_applicable_orders: number;
  applicable_orders: number;
  discount_amount: number;
  discount_pct: number;
  discount_service_pct: number;
}

export const apiToPromoCode = (promocode: PromoCodeResponse): PromoCode => ({
  id: promocode.id,
  name: promocode.name,
  remainingApplicableOrders: promocode.remaining_applicable_orders,
  applicableOrders: promocode.applicable_orders,
  discountAmount: promocode.discount_amount,
  discountPct: promocode.discount_pct,
  discountServicePct: promocode.discount_service_pct,
  type: PromoType.Manual,
});
