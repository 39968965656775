import PropTypes from 'prop-types';
import React, { useContext, useEffect, useLayoutEffect, useState, createContext } from 'react';

import { DEFAULT_DICTIONARY, DEFAULT_LOCALE } from './Locale.definition';
import { formatCurrency, formatDate, getCachedDictionary, getDictionary, translate } from './modules';

const LocaleContext = createContext({
  formatCurrency: (value = '', currency = '') => `${value} ${currency}`.trim(),
  formatDate: (value = new Date()) => value.toISOString(),
  locale: DEFAULT_LOCALE,
  translate: ({ id } = {}) => id,
});

const useLocale = () => useContext(LocaleContext);

const LocaleProvider = ({
  children,
  dictionaries = [DEFAULT_DICTIONARY],
  locale = DEFAULT_LOCALE,
  onCache,
  onError = () => {},
  onReady,
}) => {
  const [dictionary, setDictionary] = useState(undefined);

  useLayoutEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        const cachedDictionary = await getCachedDictionary({ keys: dictionaries, locale });
        setDictionary(cachedDictionary);
        if (onCache && cachedDictionary) onCache();
      } catch (error) {
        onError(error);
      }
    });

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!locale) return;

      try {
        const nextDictionaries = await getDictionary({ keys: dictionaries, locale, onError });
        setDictionary(nextDictionaries);
        onReady && onReady();
      } catch (error) {
        onError(error);
      }
    });

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        formatCurrency: (value, currency) => formatCurrency({ currency, locale, value }),
        formatDate: (value, options) => formatDate({ locale, options, value }),
        locale,
        translate: (key = {}, bindings = {}) => translate({ dictionary, key, bindings }),
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node,
  dictionaries: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string.isRequired,
  onCache: PropTypes.func,
  onError: PropTypes.func,
  onReady: PropTypes.func,
};

export { LocaleContext, LocaleProvider, useLocale };
