import { PromoCode, PromoType } from '@package/core/domain';

interface PromoCodeResponse {
  name: string;
  remaining_applicable_orders: number;
  applicable_orders: number;
}

export const apiToPromoCode = (promocode: PromoCodeResponse): PromoCode => ({
  name: promocode.name,
  remainingApplicableOrders: promocode.remaining_applicable_orders,
  applicableOrders: promocode.applicable_orders,
  type: PromoType.MultiBox,
});
