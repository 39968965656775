export const TEXT = {
  PROMO_LINK: { id: 'myLK.shipAndPay.promocode_cta_estado1' },
  PROMO_APPLY_CODE: { id: 'myLK.shipAndPay.promocode_cta_estado3' },
  PROMO_CHANGE_CODE: { id: 'myLK.shipAndPay.promocode_cta_estado4' },
  PROMO_PLACEHOLDER: { id: 'myLK.shipAndPay.promocode_box_placeholder' },
};

export const KEYBOARD = {
  ENTER: 'Enter',
};

export const IDS = {
  ADD_PROMO_LINK: 'AddPromoLink',
  ADD_PROMO_INPUT: 'AddPromoInput',
  ADD_PROMO_BUTTON: 'AddPromoButton',
};
