var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { IS_JEST } from './isJest';
const DEFAULT_LOCALE = IS_JEST ? 'en-EN' : undefined;
export const toLocale = (date, _a = {}) => {
    var { locale = DEFAULT_LOCALE } = _a, options = __rest(_a, ["locale"]);
    return date.toLocaleDateString(locale, options);
};
