import { BUTTON_VARIANT, useDevice, ViewProperties } from '@lookiero/aurora';
import {
  ApiError,
  DEVICE,
  OrderId,
  PromoCode,
  PromoCodeError,
  PromoType,
  SERVICE,
  UserSubscription,
} from '@package/core/domain';
import { PromoParam } from '@package/core/domain/PromoParam';
import { useEffectEvent } from '@package/ui/hooks/useEffectEvent';
import { useValidatePromo } from '@package/ui/hooks/useValidatePromo';
import { useStrategy } from '@package/ui/providers/StrategyProvider';
import React, { useEffect, useState } from 'react';

import { PromoInput } from '../PromoInput';

export type SetIsValidState = React.Dispatch<React.SetStateAction<boolean>>;

export interface PromoComponentProps extends ViewProperties {
  onValidate: (response: PromoCode, setValidState: SetIsValidState) => void;
  onError?: (error: PromoCodeError) => void;
  onLoadingChange?: (isLoading: boolean) => void;
  service: SERVICE;
  promoCode?: PromoParam;
  loading?: boolean;
  isFirstOrder?: boolean;
  orderId?: OrderId;
  userSubscription?: UserSubscription;
  disabled?: boolean;
  hideInput?: boolean;
  showLink?: boolean;
  hideButtonOnEmpty?: boolean;
  buttonVariant?: BUTTON_VARIANT;
  buttonSmall?: boolean;
}

const PromoComponent: React.FC<PromoComponentProps> = ({
  onValidate,
  onError = () => undefined,
  onLoadingChange = () => undefined,
  promoCode,
  loading,
  service,
  isFirstOrder = false,
  orderId,
  userSubscription = UserSubscription.OCCASIONAL,
  hideInput = false,
  showLink = false,
  buttonSmall = true,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(false);
  const [changeCodeLabel, setChangeCodeLabel] = useState(false);
  const { mutate: validatePromoCodeMutation, isLoading: isValidatingPromoCode } = useValidatePromo();

  const { toPromoCodeError, toPromoCodeSuccess } = useStrategy();

  const { platform } = useDevice();
  const device = platform.web ? DEVICE.WEB : DEVICE.APP;

  const handleSuccess = (promoParam: PromoParam) => (response: PromoCode) => {
    setIsValid(true);
    setChangeCodeLabel(true);
    onValidate(toPromoCodeSuccess({ ...response, type: promoParam.type }), setIsValid);
  };

  const handleError = (promoParam: PromoParam) => (error: ApiError) => {
    setIsValid(false);
    setChangeCodeLabel(false);
    onError(toPromoCodeError(promoParam, error));
  };

  const handleOnValidate = useEffectEvent(({ value, type = PromoType.Manual }: PromoParam) => {
    const params: PromoCode = { name: value, device, service, isFirstOrder, orderId, userSubscription };
    validatePromoCodeMutation(params, {
      onSuccess: handleSuccess({ value, type }),
      onError: handleError({ value, type }),
    });
  });

  useEffect(() => {
    onLoadingChange(isValidatingPromoCode);
  }, [onLoadingChange, isValidatingPromoCode]);

  useEffect(() => {
    if (promoCode && promoCode.value) handleOnValidate(promoCode);
  }, [promoCode, handleOnValidate]);

  const isLoading = isValidatingPromoCode || loading;

  if (hideInput) return <></>;

  return (
    <PromoInput
      value={promoCode?.value}
      onApply={(value) => handleOnValidate({ value })}
      onChange={() => setIsValid(false)}
      loading={isLoading}
      valid={!isLoading && isValid}
      changeCodeLabel={changeCodeLabel}
      showLink={!isLoading && showLink}
      buttonSmall={buttonSmall}
      {...rest}
    />
  );
};

export { PromoComponent };
