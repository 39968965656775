import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    field: {
        flex: 1,
        width: '100%',
    },
    separator: {
        width: 1,
        height: '100%',
    },
});
