import { LocaliseParams, ApiError, PromoCodeError, PromoCode, SERVICE, PromoParam } from '@package/core/domain';
import { BoxLocoMap, ShopLocoMap } from '@package/core/infrastructure/localization/';
import { localizationMapper } from '@package/core/shared';

import { ToPromoCodeSuccessBox, ToPromoCodeErrorBox } from './box/';
import { CommonApplication } from './common';
import { ToPromoCodeSuccessShop, ToPromoCodeErrorShop } from './shop/';

type ServiceApplicationReturnType = {
  toPromoCodeError: (promoParam: PromoParam, error: ApiError, params?: LocaliseParams | undefined) => PromoCodeError;
  toPromoCodeSuccess: (promoCode: PromoCode) => PromoCode;
};

export type ApplicationReturnType = typeof CommonApplication & ServiceApplicationReturnType;

const createServiceApplication = (service: SERVICE): ServiceApplicationReturnType => {
  const serviceApplication = {
    [SERVICE.BOX]: {
      localiseMap: localizationMapper(BoxLocoMap),
      ToPromoCodeSuccess: ToPromoCodeSuccessBox,
      ToPromoCodeError: ToPromoCodeErrorBox,
    },
    [SERVICE.SHOP]: {
      localiseMap: localizationMapper(ShopLocoMap),
      ToPromoCodeSuccess: ToPromoCodeSuccessShop,
      ToPromoCodeError: ToPromoCodeErrorShop,
    },
  };
  const { localiseMap, ToPromoCodeSuccess, ToPromoCodeError } = serviceApplication[service];

  return {
    toPromoCodeSuccess: ToPromoCodeSuccess(localiseMap),
    toPromoCodeError: ToPromoCodeError(localiseMap),
  };
};

export const Application = (service: SERVICE): ApplicationReturnType => {
  return {
    ...CommonApplication,
    ...createServiceApplication(service),
  };
};
