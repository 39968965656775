import { LocaliseMapper, ApiError, PromoCodeError, LocaliseError, PromoParam } from '@package/core/domain';

export const ToPromoCodeError =
  (getErrorObject: (error: ApiError) => LocaliseError) =>
  (localiseMapper: LocaliseMapper) =>
  (promoParam: PromoParam, error: ApiError): PromoCodeError => {
    const { id, params } = getErrorObject(error);

    return {
      promoCode: promoParam,
      rawError: error.rawError,
      errorCode: error.errorCode,
      errorMessage: error.errorMessage,
      localise: localiseMapper.getLocaliseError(id, params),
    };
  };
