// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Children } from 'react';
import { getInputErrors } from '../../../../helpers';
import { getField } from './getField';
export const getChildrenErrors = ({ children, schema = {}, values = {}, touched = {}, forceCheck = false } = {}) => {
    const errors = {};
    Children.forEach(children, ({ props = {} } = {}) => {
        const field = getField(props);
        if (field && (forceCheck || touched[field])) {
            const inputErrors = getInputErrors(Object.assign(Object.assign(Object.assign({}, props), schema[field]), { value: values[field] }));
            if (inputErrors)
                errors[field] = inputErrors;
        }
    });
    return errors;
};
