var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useLayoutEffect, useState } from 'react';
import { ALIGN, COLOR, POINTER, POSITION, SIZE, styles } from '../../../hooks';
import { Icon, Image, Motion, View } from '../../primitives';
import { style } from './Thumbnail.style';
import { Skeleton } from '../Skeleton';
const Thumbnail = (_a) => {
    var { alt, children, height, icon, resizeMode = 'cover', src, srcSet, width } = _a, others = __rest(_a, ["alt", "children", "height", "icon", "resizeMode", "src", "srcSet", "width"]);
    const [loaded, setLoaded] = useState(false);
    useLayoutEffect(() => {
        setLoaded(false);
    }, [src]);
    const handleLoad = () => {
        setLoaded(true);
    };
    const common = {
        style: styles({ height, width }, others.style),
    };
    return (React.createElement(View, Object.assign({ position: POSITION.RELATIVE }, others, common),
        src && React.createElement(Image, Object.assign({}, common, { alt: alt, onLoad: handleLoad, resizeMode: resizeMode, src: src, srcSet: srcSet })),
        React.createElement(Motion, Object.assign({}, common, { alignItems: ALIGN.CENTER, backgroundColor: COLOR.BG_INTERACTIVE_ACTIVE, delay: loaded, justifyContent: ALIGN.CENTER, layer: SIZE.XS, pointer: POINTER.NONE, position: POSITION.ABSOLUTE, value: { opacity: loaded ? 0 : 1 } }),
            !loaded && !icon && React.createElement(Skeleton, { position: POSITION.ABSOLUTE, style: style.skeleton }),
            !loaded && icon && React.createElement(Icon, { name: icon, color: COLOR.ICON }),
            children)));
};
Thumbnail.displayName = 'Thumbnail';
export { Thumbnail };
