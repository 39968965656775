import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    display: 'flex',
    height: '$iconSize',
    width: '$iconSize',
    marginLeft: 'auto',
    marginRight: 17,
    top: '$spaceXS * -10',
    marginBottom: '$iconSize * -1',
  },
});
